import Vue from 'vue'
import App from './App.vue'
import "../src/assets/css/base.css"
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import * as echarts from 'echarts';
import 'echarts-wordcloud';
import router from "./router/index"
import axios from 'axios';
import {
  createPinia
} from 'pinia';
import {
  deleteCookie
} from "@/utils/cookie"

import locale from 'element-ui/lib/locale/lang/en'

Vue.use(ElementUI, { locale })
//axios.defaults.baseURL = process.env.VUE_APP_API;

// 添加响应拦截器
axios.interceptors.response.use(
  response => response,
  error => {
    const {
      status
    } = error.response;
    if (status === 401) {
      deleteCookie("X-Token", "/")
      router.push('/');
    }
    // return Promise.reject(error);
  }
);

Vue.prototype.$echarts = echarts;

Vue.config.productionTip = false


Vue.use(ElementUI);
const pinia = createPinia();
Vue.use(pinia)
Vue.filter('thousandsSeparator', function (value) {
  if (value) {
    return value.toLocaleString('en-US', {
      useGrouping: true
    });
  }
});

new Vue({
  router,
  pinia,
  render: h => h(App),
}).$mount('#app')

router.beforeEach((to, from, next) => {

  next()
})