import Vue from "vue";
import Router from "vue-router";
import getCookie from "@/utils/cookie";
import deleteCookie from "@/utils/cookie";
import axios from "axios";
import {
  useMyStore
} from "@/store/index";
// 引入page1和page2组件

Vue.use(Router);

const routes = [{
  path: "/",
  component: () => import("@/views/login_home"),
  children: [{
    path: "",
    name: "Login",
    component: () => import("@/views/login"),
  },
  {
    path: "/home",
    component: () => import("@/views/home"),
  },
  ],
},
{
  path: "/DIGITALPULSE",
  component: () => import("@/views/DIGITALPULSE"),
  meta: {
    requiresAuth: true,
    role: 'XXX_Module1'
  }
},
{
  path: "/INDUSTRYCOMPETITORS",
  component: () => import("@/views/INDUSTRYCOMPETITORS"),
  meta: {
    requiresAuth: true,
    role: 'XXX_Module2'
  }
},
{
  path: "/DigitalMediaMonthly",
  component: () => import("@/views/DigitalMediaMonthly"),
  meta: {
    requiresAuth: true,
    role: 'XXX_Module3'
  }
},
{
  path: "/AMPLIFICATIONANDTAXONOMY",
  component: () => import("@/views/AMPLIFICATIONANDTAXONOMY"),
  meta: {
    requiresAuth: true,
    role: 'XXX_Module4'
  }
},
{
  path: "/KOLMANAGEMENT",
  component: () => import("@/views/KOLMANAGEMENT"),
  meta: {
    requiresAuth: true,
    role: 'XXX_Module5'
  }
},
{
  path: "/404",
  component: () => import("@/views/404"),
},
];

const originalPush = Router.prototype.push;
const originalReplace = Router.prototype.replace;
//push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};
//replace
Router.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch((err) => err);
};

const router = new Router({
  routes,
});
let list = [];
router.beforeEach(async (to, from, next) => {
  // if (to.name == "Login") {
  //   window.location = `${process.env.VUE_APP_IMG_URL}dmp/api/saml2/index`;
  //   //用于本地调试，部署服务器时next()注释掉，上面的去掉注释
  //   //next();
  // } 
  if (to.name == "Login" && process.env.VUE_APP_API_HEAD == "/dmp/") {
    window.location = `${process.env.VUE_APP_IMG_URL}dmp/api/saml2/index`;
  } else if (to.name == "Login" && process.env.VUE_APP_API_HEAD == "/") {
    next();}
   else if (to.path == "/404") {
    deleteCookie("X-Token");
    next();
  } else {
    //获取querystring
    let mac = to.query.mac;
    if (mac) {
      document.cookie = "X-Token=" + mac;
      window.location = `${process.env.VUE_APP_IMG_URL}#/home`
    }
    let Cookie = getCookie("X-Token");

    if (Cookie) {
      await axios({
        method: "get",
        url: process.env.VUE_APP_API_HEAD + "api/Check/GetResources",
        headers: {
          "X-Token": Cookie,
        },
      }).then((res) => {
        if (res.data.code == 200) {
          useMyStore().count = res.data.result.map((item) => item.id);
          list = res.data.result.map((item) => item.id);
          localStorage.setItem("count", list);
          if (list.includes("XXX_VUE")) {
            if (to.meta.requiresAuth) {
              if (to.meta.role) {
                if (list.includes(to.meta.role)) {
                  next();
                }
              }
            } else {
              next();
            }
          } else if (list.includes("XXX_Admin")) {
            window.location = `${process.env.VUE_APP_ADMINWEB_URL}admin/#/dashboard`;
          } else {
            next("/404");
          }
        }
      });
    } else {
      next({
        name: "Login",
      });
    }
  }
});

export default router;