import {
    defineStore
} from 'pinia';

export const useMyStore = defineStore({
    id: 'myStore',
    state: () => ({
        count: [],
    }),
    actions: {
        increment() {

        },
        decrement() {

        },
    },
});